/** LOGO **/

@media (min-width: 768px) and (max-width: 991px) { 
    header .site-logo {
        height: 55px;   /* the logo is too big to fit with the other stuff in the header */
        }
    }
// @media (min-width: 992px) and (max-width: 1199px) {
//     header .site-logo {
//         height: 60px;  /* not needed on a proper tc site, as there is less in the header 
//         }
//     }

/** NAVIGATION **/

.navbar-default {
    background:#000;
    }
.navbar-default .navbar-nav > li > a {
    font-weight:bold;    
    text-transform:uppercase;
    }


/** LOGIN **/

header.login-header .login-content-container h1 {
    display:none;
    }
.login .body .side-section .side-section-body .side-section-body-container {
    background: white;
    padding: 20px;
    }



.page-operator {

    //TC-19555 fixes width in messages
    #cv-messages-list .widget-chat-message p, 
    p#edit-description-display {
        max-width: 380px;
    }

    // TC-19555 CV and applications long text in top panel fix
    .bg-primary.banner.d-align-items-center {
        .progress {
            margin-top: 20px;
        }

        .count {
            margin: 0 auto 10px;
        }

        > div > div.d-flex-md {
            @media (min-width: 992px) {
                max-width: 650px;
            }
    
            @media (min-width: 1200px) {
                max-width: 850px;
            }
        }

        // roundels looking squashed only in IE11
        .justify-content-md-center.d-flex-md.top-margin-md.hidden-xs.hidden-sm {
            min-width: 130px;
        }

    }

}
